import React, { useState, useEffect, useRef } from "react"

import Map from "./map"
import MapDetails from "./map-details"

import { getSchoolData } from "../../util/api"
import schoolsToGeoJson from "../../util/schoolsToGeoJson"
import { Marker } from "@urbica/react-map-gl"

export default function InteractiveMap({
  selectedSchoolId,
  onSelectedSchoolIdChange,
  startingViewport = {
    latitude: 48.20223243315667,
    longitude: 16.34732656166466,
    zoom: 10.5,
  },
}) {
  const [schools, setSchools] = useState([])
  const schoolsGeoJson = schoolsToGeoJson(schools)

  useEffect(() => {
    async function fetchSchools() {
      setSchools(await getSchoolData())
    }
    fetchSchools()
  }, [])

  function setSelectedSchoolId(id) {
    onSelectedSchoolIdChange(id)
  }

  const mapRef = useRef()

  const selectedSchool = schools.find(school => school.id === selectedSchoolId)

  const [viewport, setViewport] = useState({
    width: "100%",
    latitude: selectedSchool?.latitude || startingViewport.latitude,
    longitude: selectedSchool?.longitude || startingViewport.longitude,
    zoom: selectedSchool ? 16 : startingViewport.zoom,
  })

  function handleMarkerClicked(map, event) {
    const markerFeature = event.features[0]

    map.flyTo({
      center: markerFeature.geometry.coordinates,
      zoom: 16,
      offset: [0, -120],
    })

    setSelectedSchoolId(markerFeature.properties.id)
  }

  function handleViewportChange(newViewport) {
    if (
      selectedSchool &&
      (newViewport.latitude !== viewport.latitude ||
        newViewport.longitude !== viewport.longitude)
    ) {
      setSelectedSchoolId(null)
    }

    setViewport(newViewport)
  }

  useEffect(() => {
    if (selectedSchool?.name) {
      mapRef.current.getMap().flyTo({
        center: [
          selectedSchool.coordinates.longitude,
          selectedSchool.coordinates.latitude,
        ],
        offset: [0, -120],
        zoom: viewport.zoom > 16 ? viewport.zoom : 16,
      })
    }
  }, [selectedSchool])

  return (
    <Map
      mapRef={mapRef}
      dataSource={schoolsGeoJson}
      viewport={viewport}
      onViewportChange={handleViewportChange}
      onMarkerClicked={handleMarkerClicked}
    >
      {selectedSchool && (
        <Marker
          latitude={selectedSchool.coordinates.latitude}
          longitude={selectedSchool.coordinates.longitude}
        >
          <MapDetails
            schoolType={selectedSchool.type}
            name={selectedSchool.name}
            address={selectedSchool.address}
            website={selectedSchool.website}
            style={{
              transform: "translate(-50%, 5px)",
            }}
          />
        </Marker>
      )}
    </Map>
  )
}
