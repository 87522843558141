import React from "react"
import * as styles from "./action-box.module.scss"

export default ({ icon, text, actionItem }) => (
  <div className={styles.container}>
    <div className={styles.tiltInner}>
      <div className={styles.icon}>{icon}</div>
      <h3>{text}</h3>
      {actionItem}
    </div>
  </div>
)
