import React, { Fragment } from "react"
import { Waypoint } from "react-waypoint"
import * as styles from "./school.module.scss"
import School from "../../images/school.inline.svg"

const animePromise = import("animejs")

function animateFlag(anime, flag, endheight, duration, delay) {
  flag.forEach(function(item) {
    anime({
      targets: item,
      height: endheight,
      delay: delay,
      duration: duration,
      easing: "easeOutBounce",
    })
  })
}

export default () => (
  <Fragment>
    <Waypoint
      topOffset="60%"
      onEnter={async () => {
        const rightFlag = [
          "#Path1_1_",
          "#Path2_1_",
          "#Path3_1_",
          "#Path4_1_",
          "#Path5_1_",
          "#Path6_1_",
        ]
        const leftFlag = [
          "#Path1",
          "#Path2",
          "#Path3",
          "#Path4",
          "#Path5",
          "#Path6",
        ]

        const anime = (await animePromise).default

        animateFlag(anime, rightFlag, 271, 2000, 0)
        animateFlag(anime, leftFlag, 271, 2000, 200)
      }}
    />
    <div className={styles.schoolContainer}>
      <School />
    </div>
  </Fragment>
)
