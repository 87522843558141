import React from "react"
import * as styles from "./download-section.module.scss"

import documentIcon from "../../images/document-rainbow.svg"

export default ({ smallTitle, title, linkText, link }) => (
  <div className={styles.downloadSection}>
    <img src={documentIcon} alt="" />
    <div className={styles.downloadInfo}>
      <span className={styles.smallCaps}>{smallTitle}</span>
      <h2>{title}</h2>
      <p>
        <a href={link}>{linkText}</a>
      </p>
    </div>
  </div>
)
