export default function schoolsToGeoJson(schools) {
  const geoJson = {
    features: schools.map(school => ({
      type: "Feature",
      properties: {
        type: "school",
        name: school.name,
        id: school.id,
      },
      geometry: {
        coordinates: [
          school.coordinates.longitude,
          school.coordinates.latitude,
          0,
        ],
        type: "Point",
      },
      id: school.id,
    })),
    type: "FeatureCollection",
  }

  return geoJson
}
