import React, { Suspense } from "react"

const Anime = React.lazy(() => import("react-anime"))

export default ({ children, ...otherProps }) => {
  const isSSR = typeof window === "undefined"

  // Suspense isn't supported with SSR yet
  if (isSSR) {
    return children
  }

  return (
    <Suspense fallback={children}>
      <Anime {...otherProps}>{children}</Anime>
    </Suspense>
  )
}
