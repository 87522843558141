import React, { useState } from "react"

import InteractiveMap from "../map/interactive-map"

import * as styles from "./map-section.module.scss"

export default function MapSection() {
  const [selectedSchoolId, setSelectedSchoolId] = useState()

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>
        Sieh dir an, wo schon überall unsere Flaggen hängen.
      </h1>
      <div className={styles.mapWrapper}>
        <InteractiveMap
          selectedSchoolId={selectedSchoolId}
          onSelectedSchoolIdChange={newId => setSelectedSchoolId(newId)}
        />
      </div>
    </div>
  )
}
