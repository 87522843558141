import React from "react"
import * as styles from "./scroll-down.module.scss"
import LazyAnime from "../../util/lazy-anime"

import arrowImg from "../../images/arrow.svg"

export default () => (
  <div className={styles.scrollDown}>
    <p className={styles.scrollDownText}>Scrollen</p>
    <LazyAnime
      translateY={[0, 10]}
      delay={500}
      loop={true}
      direction={"alternate"}
      duration={400}
      easing={"easeInOutSine"}
    >
      <img src={arrowImg} alt="" />
    </LazyAnime>
  </div>
)
