import React, { createRef } from "react"

import * as Sentry from "@sentry/browser"

import MapGL, {
  LanguageControl,
  Image,
  Source,
  Layer,
} from "@urbica/react-map-gl"
import "mapbox-gl/dist/mapbox-gl.css"

import { useStaticQuery, graphql } from "gatsby"

import * as styles from "./map.module.scss"

function getDarkOrLightModeStyle() {
  if (
    typeof window !== "undefined" && // needed for SSR
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    // Dark Mode Style Map
    return "mapbox://styles/hosiwien-flagincluded/ckb5ehm8x2qj21imxteohjh5b"
  } else {
    // Light Mode Style Map
    return "mapbox://styles/hosiwien-flagincluded/ckb52rrkq183j1hp7xvgm57yn"
  }
}

export default function Map({
  viewport,
  onViewportChange,
  dataSource,
  mapRef = createRef(),
  onMarkerClicked = (map, event) => {},
  children,
}) {
  const data = useStaticQuery(graphql`
    {
      flagMarker: file(base: { eq: "map_marker.png" }) {
        ... on File {
          childImageSharp {
            resize(height: 50) {
              src
              width
              height
            }
          }
        }
      }
      clusterMarker2: file(base: { eq: "cluster_marker.png" }) {
        ... on File {
          childImageSharp {
            resize(height: 50) {
              src
              width
              height
            }
          }
        }
      }
      clusterMarkerGt2: file(base: { eq: "cluster_marker_morethan2.png" }) {
        ... on File {
          childImageSharp {
            resize(height: 50) {
              src
              width
              height
            }
          }
        }
      }
    }
  `)
  const flagMarker = data.flagMarker.childImageSharp.resize
  const clusterMarker2 = data.clusterMarker2.childImageSharp.resize
  const clusterMarkerGt2 = data.clusterMarkerGt2.childImageSharp.resize

  function handleClusterClicked(event) {
    const clusterFeature = event.features[0]
    const clusterId = clusterFeature.properties.cluster_id
    const map = mapRef.current.getMap()

    map
      .getSource("schools")
      .getClusterExpansionZoom(clusterId, function(err, zoom) {
        if (err) {
          Sentry.captureException(err)
          return
        }

        map.easeTo({
          center: clusterFeature.geometry.coordinates,
          zoom: zoom * 1.1, // add a litle extra oomph for better user experience
        })
      })
  }

  function handleMarkerClicked(event) {
    onMarkerClicked(mapRef.current.getMap(), event)
  }

  return (
    <MapGL
      {...viewport}
      onViewportChange={onViewportChange}
      accessToken={process.env.MAPBOX_ACCESS_TOKEN}
      mapStyle={getDarkOrLightModeStyle()}
      viewportChangeMethod="flyTo"
      ref={mapRef}
      className={styles.mapContainer}
    >
      <Image id="flag-marker" image={flagMarker.src} />
      <Image id="cluster-marker-2" image={clusterMarker2.src} />
      <Image id="cluster-marker-gt2" image={clusterMarkerGt2.src} />
      <Source id="schools" type="geojson" data={dataSource} cluster={true} />
      <Layer
        id="clusters-2"
        type="symbol"
        source="schools"
        filter={["all", ["has", "point_count"], ["==", "point_count", 2]]}
        layout={{
          "icon-allow-overlap": true,
          "icon-image": "cluster-marker-2",
          "icon-offset": [1, -6],
        }}
        onClick={handleClusterClicked}
      />

      <Layer
        id="clusters-gt2"
        type="symbol"
        source="schools"
        filter={["all", ["has", "point_count"], [">", "point_count", 2]]}
        layout={{
          "icon-allow-overlap": true,
          "icon-image": "cluster-marker-gt2",
          "icon-offset": [3, -8],
        }}
        onClick={handleClusterClicked}
      />

      <Layer
        id="cluster-count"
        type="symbol"
        source="schools"
        filter={["has", "point_count"]}
        layout={{
          "text-field": "{point_count_abbreviated}",
          "text-font": ["Circular Medium"],
          "text-size": 16,
          "text-allow-overlap": true,
        }}
      />
      <Layer
        id="unclustered-places"
        type="symbol"
        source="schools"
        filter={["!has", "point_count"]}
        layout={{
          "icon-image": "flag-marker",
          "icon-offset": [0, -flagMarker.height / 2 + 15],
        }}
        onClick={handleMarkerClicked}
      />
      <LanguageControl language="de" />
      {children}
    </MapGL>
  )
}
