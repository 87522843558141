import React from "react"
import * as styles from "./map-details.module.scss"

import { OutboundLink } from "react-ga"

import LocationPin from "../../images/location-pin.inline.svg"

export default function MapDetails({
  schoolType,
  name,
  address,
  website,
  thumbmailImage,
  quote,
  style,
}) {
  console.log(styles)
  return (
    <div className={styles.box} style={style}>
      {thumbmailImage && (
        <div
          className={styles.schoolImage}
          style={{ backgroundImage: `url(${thumbmailImage})` }}
        ></div>
      )}
      <div className={styles.schoolInfoText}>
        <div>
          <span className={styles.schoolType}>{schoolType}</span>
          <h1 className={styles.schoolName}> {name}</h1>
          <div className={styles.locationwebsite}>
            <div className={styles.locationRow}>
              <LocationPin className={styles.locationPin} />
              <p>
                {address}{" "}
                <span className={styles.seperatorLineAdressLocation}>
                  &nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                </span>
              </p>
            </div>
            <OutboundLink eventLabel={website} to={website}>
              {website}
            </OutboundLink>
          </div>
        </div>
        {quote && (
          <>
            <hr />
            <p>{quote.text}</p>
            <span className={styles.quoteAuthor}>{quote.author}</span>
          </>
        )}
      </div>
    </div>
  )
}
