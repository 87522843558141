// extracted by mini-css-extract-plugin
export var box = "map-details-module--box--yHEr+";
export var locationPin = "map-details-module--locationPin--vJFGs";
export var locationRow = "map-details-module--locationRow--pNyAA";
export var locationwebsite = "map-details-module--locationwebsite--QwfUs";
export var quoteAuthor = "map-details-module--quoteAuthor--+xpvW";
export var schoolImage = "map-details-module--schoolImage--at1wY";
export var schoolInfoText = "map-details-module--schoolInfoText--dBTRn";
export var schoolName = "map-details-module--schoolName--SxEVx";
export var schoolType = "map-details-module--schoolType--gyB9w";
export var seperatorLineAdressLocation = "map-details-module--seperatorLineAdressLocation--mXr1I";