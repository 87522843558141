import React, { Fragment } from "react"
import * as styles from "./claim-with-image.module.scss"

import { Center } from "../layout"

export default ({ image, claim, actionItem }) => (
  <div className={styles.container}>
    {image}
    <Center>
      <h3 className={styles.claim}>{claim}</h3>
    </Center>
    {actionItem && (
      <Fragment>
        <Center>{actionItem}</Center>
      </Fragment>
    )}
  </div>
)
