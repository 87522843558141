import React from "react"
import * as styles from "./rainbow-hero.module.scss"

export default () => (
  <div className={styles.hero}>
    <div className={styles.rainbow}>
      <div className={styles.rainbowStripe}></div>
      <div className={styles.rainbowStripe}></div>
      <div className={styles.rainbowStripe}></div>
      <div className={styles.rainbowStripe}></div>
      <div className={styles.rainbowStripe}></div>
      <div className={styles.rainbowStripe}></div>
    </div>
    <div className={styles.heroQuote}>
      <h2 className={styles.heroQuoteText}>
        “The rainbow flag has become far more than just a flag, it gives people
        hope.”
      </h2>
      <hr className={styles.seperator} />
      <p className={styles.heroQuoteAuthor}>
        Gilbert Baker, Erfinder der Regenbogen-Flagge{" "}
      </p>
    </div>
  </div>
)
